.navbar {
  background: linear-gradient(to right, #00000000, #19191900);
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  backdrop-filter: blur(10px);
  font-family: "AssistantR";
  padding: 0 20px;
  z-index: 999;
  justify-content: space-between;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: transform;
  box-sizing: border-box;
  position: relative;
  transform: translateY(0);
  box-shadow: 0 0 5px 2px rgba(45, 45, 45, 0.345);
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  z-index: 1000;
  height: 80px;
  animation: slideDown 0.8s ease-in-out;
  box-shadow: 0 0 5px 2px rgba(45, 45, 45, 0.345);
}

@keyframes slideDown {
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(0);
  }
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo img {
  height: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.socialIcons {
  display: flex;
  gap: 10px;
}

.socialIcons a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 25px;
  background-color: none;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;
}

.hamburger {
  cursor: pointer;
  font-size: 1.5rem;
  color: black;
  z-index: 1001;
  display: flex;
  align-items: center;
}

.menuItems {
  display: flex;
  gap: 30px;
  direction: rtl;
}

.menuItems a {
  color: black;
  text-decoration: none;
  font-family: "AssistantR";
  font-size: 1.2rem;
  cursor: pointer;
}

.mobileMenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(146deg, rgb(247, 235, 255) 15%, rgb(255, 255, 255) 35%, rgb(255, 255, 255) 60%, rgb(247, 235, 255) 85%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: revealMenu 0.5s ease-out forwards;
}

.mobileMenu.closing {
  animation: closeMenu 0.5s ease-in forwards;
}

.mobileMenuContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInContent 0.5s ease-out 0.3s forwards;
}

.closing .mobileMenuContent {
  animation: fadeOutContent 0.5s ease-in forwards;
}

.mobileMenuItem {
  color: black;
  text-decoration: none;
  font-size: 1.5rem;
  font-family: "AssistantR";
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
}

.image {
  width: 80px;
  object-fit: contain;
}

@keyframes revealMenu {
  from {
    clip-path: circle(0% at top right);
  }
  to {
    clip-path: circle(150% at top right);
  }
}

@keyframes closeMenu {
  from {
    clip-path: circle(150% at top right);
  }
  to {
    clip-path: circle(0% at top right);
  }
}

@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutContent {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

@media (max-width: 850px) {
  .menuItems {
    display: none;
  }
}