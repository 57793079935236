.container {
    display: flex;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
  }
  
  .header {
    font-size: 3rem;
    font-family: "Assistant";
    text-align: center;
    margin: 0 auto 2%;
    padding-top: 2%;
    direction: rtl;
    color: #000;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .imageContainer {
    flex: 1;
    padding-right: 20px;
    position: relative;
  }
  
  .image {
    width: 70%;
    display: flex;
    height: auto;
    max-height: 500px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all 1s ease;
    position: relative;
    z-index: 2;
  }

  
  .imageContainer:hover .image {
    transform: scale(1.05) rotate(2deg);
  }
  
  .content {
    flex: 2;
    text-align: right;
    direction: rtl;
  }
  
  .title {
    font-size: 2.5em;
    color: #000;
    margin-bottom: 10px;
    font-family: "Assistant";
  }
  
  .description {
    font-size: 1em;
    line-height: 1.6;
    font-family: "AssistantR";
    color: #000;
  }
  
  @media only screen and (max-width: 1050px) {
    .container {
      width: 90%;
      max-width: none;
    }
    .image {
        justify-content: center;
        
      }
  }
  
  @media only screen and (max-width: 350px) {
    .container {
      flex-direction: column;
      padding: 10px;
    }
    .image {
        margin:2% auto;
      }
    .header {
      font-size: 2.2rem;
    }
  
    .imageContainer {
      padding-right: 0;
      padding-bottom: 10px;
    }
  
    .content {
      text-align: center;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
    }
  }
  
  @media only screen and (min-width: 350px) and (max-width: 450px) {
    .container {
      flex-direction: column;
      padding: 15px;
    }
    .image {
        margin:2% auto;
      }
    .header {
      font-size: 2.4rem;
    }
  
    .imageContainer {
      padding-right: 0;
      padding-bottom: 15px;
      width: 70%;
      margin: 0 auto;
    }
  
    .content {
      text-align: center;
    }
  
    .title {
      font-size: 2.2rem;
    }
  }
  
  @media only screen and (min-width: 450px) and (max-width: 550px) {
    .container {
      flex-direction: column;
    }
  
    .header {
      font-size: 3rem;
    }
    .image {
        margin:2% auto;
      }
    .imageContainer {
      width: 60%;
      margin: 0 auto;
      padding-right: 0;
      padding-bottom: 20px;
    }
  
    .content {
      text-align: right;
    }
  
    .title {
      font-size: 2.4rem;
    }
  }
  
  @media only screen and (min-width: 550px) and (max-width: 650px) {
    .header {
      font-size: 3.1rem;
    }
    .image {
        margin:2% auto;
      }
    .title {
      font-size: 2.3em;
    }
  }
  
  @media only screen and (min-width: 650px) and (max-width: 750px) {
    .header {
      font-size: 3.2rem;
    }
  
    .title {
      font-size: 2.4em;
    }
  }
  
  @media only screen and (min-width: 750px) and (max-width: 850px) {
    .header {
      font-size: 3.3rem;
    }
  }
  
  @media only screen and (min-width: 850px) and (max-width: 950px) {
    .header {
      font-size: 3.4rem;
    }
  }
  
  @media only screen and (min-width: 950px) and (max-width: 1050px) {
    .header {
      font-size: 3.5rem;
    }
  }