.syllabusWrapper {
    font-family: 'AssistantR';
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    direction: rtl;
}
  
.container {
    max-width: 900px;
    width: 100%;
    padding: 2rem;
    background: linear-gradient(135deg, rgba(255,209,229,1) 0%, rgba(255,239,243,1) 50%, rgba(255,200,222,1) 100%);
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}
  
.mainTitle {
    font-family: 'Assistant';
    font-weight: 800;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #ffffff; /* צבע סגול לוונדר */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* צל אפור קלאסי */
}
  
.description {
    font-weight: 300;
    font-size: 1.25rem;
    color: #212121;
    text-align: right;
    margin-bottom: 2rem;
    line-height: 1.6;
}
  
.courseInfo, .kitInfo {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 2rem;
}
  
.courseInfo h3, .kitInfo h3 {
    font-family: 'Assistant';
    font-weight: 800;
    font-size: 1.3rem;
    color: #4a4a4a;
    margin-bottom: 1rem;
}
  
.courseItem, .kitInfo li {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    font-size: 1.1rem;
}
  
.checkmark {
    color: #8a2be2;
    margin-left: 0.5rem;
    font-size: 1.2rem;
}
  
.meetingsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
}
  
.meetingCard {
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid rgba(173, 216, 230, 0.3);  /* Light Blue */
    transition: all 0.3s ease;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  
.meetingCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(230, 230, 250, 0.1),  /* Lavender */
      rgba(205, 105, 255, 0.1)   /* Hot Pink */
    );
    z-index: -1;
    filter: blur(20px);
}
  
.meetingCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
  
.meetingContent {
    position: relative;
    padding: 1.5rem;
    z-index: 1;
}
  
.meetingTitle {
    font-family: 'Assistant';
    font-weight: 800;
    font-size: 1.2rem;
    color: #4a4a4a;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
  
.meetingNumber {
    background: rgba(138, 43, 226, 0.6);
    color: white;
    font-weight: 800;
    font-size: 1rem;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 0.5rem;
}
  
.meetingDescription {
    font-weight: 300;
    font-size: 1rem;
    color: #4a4a4a;
    line-height: 1.4;
    text-align: right;
}
  
@media (max-width: 600px) {
    .syllabusWrapper {
      padding: 1rem;
    }
  
    .container {
      padding: 1rem;
    }
  
    .mainTitle {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
    }
  
    .meetingTitle {
      font-size: 1.1rem;
    }
  
    .meetingDescription {
      font-size: 0.9rem;
    }
  
    .courseItem, .kitInfo li {
      font-size: 0.9rem;
    }
}